export const ADMIN_ROUTER_PATH = {
  HOME: "giao-dich",
  LIST_ADMIN: "quan-tri-vien",
  PRODUCT: "san-pham",
  ORDER: "don-hang",
  CONFIG: "cau-hinh",
  CUSTOMER: "khach-hang",
  CUSTOMER_TRANSACTION: "lich-su-giao-dich",
  CUSTOMER_ORDER: "lich-su-don-hang",
  VIP: "vip",
  LIST_PRODUCT_VIP: "danh-sach-san-pham-vip",
  DASH_BOARD: "thong-ke",
  CATEGORY: "danh-muc",
  BANK: "ngan-hang",
};
