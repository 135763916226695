const images = {
  img_background: require("./img_background.jpg"),
  img_categories: require("./img_categories.png"),
  img_change_password: require("./img_change_password.png"),
  img_customer: require("./img_customer.png"),
  img_home: require("./img_home.png"),
  img_image_empty: require("./img_image_empty.png"),
  img_insurance: require("./img_insurance.png"),
  img_logo: require("./img_logo.png"),
  img_logout: require("./img_logout.png"),
  img_monitor: require("./img_monitor.png"),
  img_order_delivery: require("./img_order_delivery.png"),
  img_product: require("./img_product.png"),
  img_reset_password: require("./img_reset_password.png"),
  img_salary: require("./img_salary.png"),
  img_settings: require("./img_settings.png"),
  img_staff: require("./img_staff.png"),
  img_team: require("./img_team.png"),
  img_transaction: require("./img_transaction.png"),
  img_user: require("./img_user.png"),
  img_user_header: require("./img_user_header.png"),
  img_vip: require("./img_vip.png"),
  index: require("./index.ts"),
};
export default images;
